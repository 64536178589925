<template>
    <div class="box">
        <div class="step_1">
            <!-- <img class="banner" src="/subject/tg_9654/wap/s1.png" alt=""> -->
            <div class="banner">
                <div @click="live800"></div>
            </div>
            <img @click="goBack" class="logo" src="/subject/tg_9654/wap/logo.png" alt="">
        </div>
        <div class="step_2">
            <img class="topTitle" src="/subject/tg_9654/wap/s2.png" alt="">
            <div class="w">
                <div class="container" @click="step_2_show = true">
                    <img src="/subject/tg_9654/wap/s50.png?v=0123" alt="">
                    <div>
                        <p>2024投资机会研究报告将出炉,投资风口在哪里？</p>
                        <p>市场资讯2024年1月12日 15:34:24</p>
                    </div>
                </div>
            </div>
        </div>
        <van-popup class="step_2_model" v-model="step_2_show" closeable :style="{ height: '70%', width: '90%' }">
            <p>2024投资机会研究报告将出炉，投资风口在哪里？</p>
            <p>
                2023年的投资一言难尽，环球股市反弹之际A股还处在3000点保卫战中。不过，这种现状在2024年会得到很大的修正甚至是改善。回顾2023年主要投资品种的表现。在这轮行情里面，黄金作为避险资产，受到了全球投资者的青睐，价格突破2100美元上涨最高至2150美元/盎司附近。外汇市场，美元指数在2023年波动较大，从年初的102.16下跌到年中的99，年末以101.69收官。如今，市场意见分歧，有人看空美元，认为其将受到降息预期的影响，但仍有人看多美元。不少全球大型投资机构预期，受益避险资金流及美国以外地区相对较弱的成长动力，2024年美元仍将受到买盘追捧。上证指数在2023年行情如过山车，大盘一度在5月9日站上3400点，到了下半年，A股逐渐走弱，年末跌破2974.93点，跌幅达到了-3.7%。
            </p>
            <p>
                从全球的经济走势看，中国经济触底反弹，美国经济表现活跃，美联储货币政策继续保持紧缩；欧日等区域则继续低迷震荡。背后的原因分析不外乎以下几个方面，首先，全球经济在2023年受到了新冠疫情的持续影响，导致了经济增长的不平衡和不确定性，以及通胀和货币政策的分化。其次，美国作为全球最大的经济体和金融市场，其财政和货币政策的变化对其他国家和地区的经济和金融市场产生了重大的影响，尤其是美联储加息周期，引发了全球资本市场的动荡和资金回流。最后，各国和地区之间的政治和贸易摩擦也加剧了市场的风险和波动，如中美之间的贸易战，以及中东和俄乌地区的地缘政治紧张等。这些因素都对各类投资品种的表现和走势产生了不同程度的影响，使得投资者面临了更多的挑战和机遇。
            </p>
            <p>
                在这样的情况下，哪些品种将成为2024年的投资风口呢？哪些品种将面临下跌的风险呢？哪些品种将保持稳定的表现呢？我们将在即将于1月25日举行的“2024全球投资机会研究报告”线上发布会上为您详细解答。《2024投资机会研究报告》由红狮金融研究院的中外专家、分析师共同参与撰写，报告涵盖黄金，白银，外汇以美元欧元和人民币在内的7个货币对，原油，股票热门赛道和板块，期货主力潜在品种，包括房地产投资思路等等。
            </p>
            <p>该报告发布会采取公益形式召开，由多家财经媒体给与支持，是投资者获取2024年投资走势参考的关键资料。期待这份由中外金融专业分析人士的投资报告对投资者的参考。</p>
            <p>香港红狮集团有限公司</p>
            <p>2024年1月12日</p>
        </van-popup>
        <div class="step_3">
            <img class="topTitle" src="/subject/tg_9654/s4.png" alt="">
            <img @click="goNewReport" class="container" src="/subject/tg_9654/s51.png" alt="">
        </div>
        <div class="step_4">
            <img class="topTitle" src="/subject/tg_9654/wap/s6.png" alt="">
            <div class="w">
                <div class="topBox">
                    <video class="video" v-if="!ckplayerObject.live" :src="isVideo" controls="controls" autoplay="autoplay"
                        muted></video>
                    <video v-if="ckplayerObject.live" id="videoPlayer" class="video-js" muted></video>
                    <!-- <div class="swiperBox" v-if="!ckplayerObject.live">
                        <swiper ref="mySwiper" :options="step_4_swiperOption">
                            <div class="swiper-slide one" @click="goVideo('2024投研-吴龙')">美联储政策转向如何影响数字货币</div>
                            <div class="swiper-slide two" @click="goVideo('2024投研-郑宏')">全球战争如何影响黄金和大宗商品</div>
                            <div class="swiper-slide three" @click="goVideo('2024投研-万良')">展望2024股市、房地产、黄金机会</div>
                            <div class="swiper-slide five" @click="goVideo('2024投研-micheal')">2024全球外汇市场回顾</div>
                            <div class="swiper-slide six" @click="goVideo('圆桌会议')">圆桌会议</div>
                        </swiper>
                    </div> -->
                </div>
                <div class="bottomBox">
                    <div @click="live800"></div>
                    <div @click="Login"></div>
                </div>
            </div>
        </div>
        <div class="step_5">
            <img class="topTitle" src="/subject/tg_9654/wap/s16.png" alt="">
            <div class="w">
                <div class="container">
                    <div class="swiperBox">
                        <swiper ref="mySwiper" :options="step_5_swiperOption">
                            <div class="swiper-slide">
                                <img class="one" src="/subject/tg_9654/s43.png" />
                                <img class="two" src="/subject/tg_9654/s38.png" />
                            </div>
                            <div class="swiper-slide">
                                <img class="one" src="/subject/tg_9654/s44.png" />
                                <img class="two" src="/subject/tg_9654/s39.png" />
                            </div>
                            <div class="swiper-slide">
                                <img class="one" src="/subject/tg_9654/s45.png" />
                                <img class="two" src="/subject/tg_9654/s40.png" />
                            </div>
                            <div class="swiper-slide">
                                <img class="one" src="/subject/tg_9654/s46.png" />
                                <img class="two" src="/subject/tg_9654/s41.png" />
                            </div>
                            <div class="swiper-slide">
                                <img class="one" src="/subject/tg_9654/s47.png" />
                                <img class="two" src="/subject/tg_9654/s42.png" />
                            </div>
                        </swiper>
                    </div>
                    <div class="btnBox">
                        <div @click="live800"></div>
                        <div @click="Login"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step_6">
            <img class="topTitle" src="/subject/tg_9654/wap/s19.png" alt="">
            <div class="w">
                <img class="cTitle" src="/subject/tg_9654/wap/s20.png" alt="">
                <div class="container">
                    <div class="item">
                        <div class="left">14:00</div>
                        <div class="right">
                            <div class="titleBox">
                                <div class="point"></div><span>开场致辞</span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">14:05</div>
                        <div class="right">
                            <div class="titleBox">
                                <div class="point"></div><span>主题演讲</span>
                            </div>
                            <div class="text">
                                <p>主题:美联储政策转向对2024数字货币影响与展望</p>
                                <p>专家:吴龙(红狮金融研究院资深分析师)</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">14:50</div>
                        <div class="right">
                            <div class="titleBox">
                                <div class="point"></div><span>主题演讲</span>
                            </div>
                            <div class="text">
                                <p>主题:全球战争对黄金和大宗商品影响与展望</p>
                                <p>专家:郑宏(红狮金融研究院资深分析师)</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">15:40</div>
                        <div class="right">
                            <div class="titleBox">
                                <div class="point"></div><span>2024股市房地产和黄金机会的展望</span>
                            </div>
                            <div class="text">
                                <p>专家:万良(红狮金融研究院资深分析师)</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">16:20</div>
                        <div class="right">
                            <div class="titleBox">
                                <div class="point"></div><span>2024全球外汇投资机会分析与展望</span>
                            </div>
                            <div class="text">
                                <p>专家:Anthony Boyajian</p>
                                <p>(HEAD OF RESEARCH OF EU FINANCIAL)</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">17:00</div>
                        <div class="right">
                            <div class="titleBox">
                                <div class="point"></div><span>圆桌会议</span>
                            </div>
                            <div class="text">
                                <p>主持人: Mandy(财经主持人)</p>
                                <p>嘉宾:吴龙，郑宏，万良，Ahtnony，Michael</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btnBox">
                    <div @click="live800"></div>
                    <div @click="Login"></div>
                </div>
            </div>
        </div>
        <div class="step_7">
            <img class="topTitle" src="/subject/tg_9654/wap/s24.png" alt="">
            <div class="w">
                <div class="container">
                    <div class="titleBox">
                        <img src="/subject/tg_9654/s26.png" alt="">
                        <img src="/subject/tg_9654/s27.png" alt="" @click="downloadReport">
                        <img src="/subject/tg_9654/s28.png" alt="">
                    </div>
                    <div class="swiperBox">
                        <swiper ref="mySwiper" :options="step_7_swiperOption">
                            <div class="swiper-slide">
                                <img src="/subject/tg_9654/s30.png" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/subject/tg_9654/s31.png" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/subject/tg_9654/s29.png" />
                            </div>
                        </swiper>
                    </div>
                    <div class="btnBox">
                        <div @click="live800"></div>
                        <div @click="Login"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step_8">
            <img class="topTitle" src="/subject/tg_9654/wap/s33.png" alt="">
            <div class="w">
                <img class="container" src="/subject/tg_9654/wap/s34.png" alt="">
            </div>
        </div>
        <div class="step_9">
            <img class="topTitle" src="/subject/tg_9654/wap/s36.png" alt="">
            <div class="w">
                <img class="container" src="/subject/tg_9654/wap/s37.png" alt="">
            </div>
        </div>
        <contactus v-if="!isApp"></contactus>
    </div>
</template>
<script>
import Contactus from '../../Contactus.vue';
import { livingRoomNow } from '../../../../api/info'
import videojs from 'video.js'
export default {
    name: 'tg_9654_WAP',
    components: {
        Contactus,
    },
    data() {
        return {
            isVideo: '/subject/tg_9654/s53.mp4',
            screenWidth: null,
            scroll: Number,
            step_2_show: false,
            step_4_swiperOption: {
                slidesPerView: 2.8,
                loop: true,
                spaceBetween: 10,
            },
            step_5_swiperOption: {
                slidesPerView: 2,
                // swiper图片之间的间隔
                spaceBetween: 20,
                centeredSlides: true,
                grabCursor: true,
                loop: true,
                preventClicks: true,
            },
            step_7_swiperOption: {
                slidesPerView: 2,
                spaceBetween: 0,
                centeredSlides: true,
                grabCursor: true,
                loop: true,
                preventClicks: true,
            },
            timer: null,
            myPlyer: null,
            options: null,
        }
    },
    computed: {
        ckplayerObject() {
            return this.$store.state.home.ckplayerObject
        },
        teacherPlayBack() {
            return this.$store.state.home.teacherPlayBack
        }
    },
    created() {
        // 传给App.vue,不展示tabbar
        this.$emit('controlShow', true);
        if (this.$route.query.isApp) {
            this.isApp = true;
        }

        this.init();
        this.timer = window.setInterval(() => {
            setTimeout(this.init(), 0)
        }, 1000 * 10)
        this.playBack();
    },
    beforeDestroy() {
        //在 beforeDestroy生命周期函数中销毁定时器
        clearInterval(this.timer);
        this.timer = null;
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        //获取屏幕尺寸
        this.screenWidth = document.body.clientWidth;
        window.onresize = () => {
            //屏幕尺寸变化
            return (() => {
                this.screenWidth = document.body.clientWidth;
            })()
        };
    },
    watch: {
        screenWidth: function (n) {
            if (n > 500) {
                this.$router.push({
                    name: "tg_9654_PC",
                });
            }
        },
    },
    methods: {
        goBack() {
            window.location.href = window.location.origin;
        },
        live800() {
            window.open(this.$parent.live800UrlUc);
        },
        Login() {
            window.open("/uc/login");
        },
        // 获取是否直播以及直播流
        init() {
            livingRoomNow({ platid: 1 }).then(res => {
                if (res.Status == 0 && res.content.length > 0) {
                    this.$store.commit('home/set_ckplayerObject', { video: res.content[0].param.pcurl, live: true })
                    this.isVideo = res.content[0].param.pcurl;
                    setTimeout(() => {
                        this.initVideo(this.isVideo);
                    }, 500)
                } else {
                    this.$store.commit('home/set_ckplayerObject', { video: '/subject/tg_9654/s53.mp4', live: false })
                    this.isVideo = '/subject/tg_9654/s53.mp4';
                    // this.myPlyer.dispose();
                }
            }).catch(err => {
                console.log(err);
            })
        },
        // 获取老师直播回放
        async playBack() {
            const data = {
                platid: 1,
                sort: 'ut,DESC',
                type: 0,
                page: 0,
                size: 10,
            }
            await this.$store.dispatch('home/getPlayBack', data);
        },
        // 跳转视频回放
        goVideo(name) {
            for (let i = 0; i < this.teacherPlayBack.length; i++) {
                if (this.teacherPlayBack[i].name == name) {
                    this.isVideo = this.teacherPlayBack[i].fpath
                    clearInterval(this.timer);
                    this.timer = null;
                    return
                }
            }
        },
        // 初始化videojs内容
        initVideo(nowPlayVideoUrl) {
            // 这些options属性也可直接设置在video标签上，见 muted
            this.options = {
                autoplay: true, // 设置自动播放
                controls: true, // 显示播放的控件
                sources: [
                    // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
                    {
                        src: nowPlayVideoUrl,
                        type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
                    }
                ]
            };
            // videojs的第一个参数表示的是，文档中video的id
            this.myPlyer = videojs("videoPlayer", this.options, function onPlayerReady() {
            });
        },
        // 控制顶部导航栏的定位
        handleScroll() {
            this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
            if (this.scroll > 90) {
                this.navBarFixed = true;
            } else {
                this.navBarFixed = false;
            }
        },
        // 下载报告书
        downloadReport() {
            location.href = "/subject/tg_9654/2024_globalReport.pdf?v=0126"
        },
        //往届回顾 跳转
        goNewReport() {
            location.href = "/#/subject/newReport/wap"
        },
    }
}
</script>
<style lang="less" scoped>
.w {
    width: 94%;
    margin: 0 auto;
}

.step_1 {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 490px;
        background: url('/subject/tg_9654/wap/s1.png') no-repeat;
        background-size: cover;
        box-sizing: border-box;
        padding-top: 263px;

        div {
            width: 100%;
            height: 60px;
            cursor: pointer;
        }
    }

    .logo {
        width: 125px;
        position: absolute;
        top: 16%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.step_2 {
    width: 100%;
    background: linear-gradient(#f7faff, #deedff);
    box-sizing: border-box;
    padding: 25px 0;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .container {
        width: 100%;
        height: 105px;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        margin-top: 20px;
        background-color: #fff;
        cursor: pointer;

        img {
            width: 122px;
            height: 105px;
        }

        div {

            box-sizing: border-box;
            padding: 10px;


            p {
                &:nth-child(1) {
                    font-size: 15px;
                    color: #333;
                }

                &:nth-child(2) {
                    font-size: 12px;
                    color: #c0c0c0;
                    margin-top: 30px;
                }
            }
        }
    }
}

.step_2_model {
    box-sizing: border-box;
    padding: 20px 20px 8px;

    p {
        color: #333;
        font-size: 14px;
        line-height: 22px;
        text-indent: 28px;
        margin-bottom: 15px;

        &:nth-child(1) {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        &:nth-child(6) {
            margin-bottom: 0;
        }

        &:nth-child(6),
        &:nth-child(7) {
            text-align: right;
        }
    }
}

.step_3 {
    width: 100%;
    height: 280px;
    background: url('/subject/tg_9654/s3.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding-top: 25px;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .container {
        display: block;
        width: 90%;
        margin: 25px auto 0;
        cursor: pointer;
    }
}

.step_4 {
    width: 100%;
    background: linear-gradient(#f7faff, #deedff);
    box-sizing: border-box;
    padding: 25px 0;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .topBox {
        width: 100%;
        height: 175px;
        margin-top: 25px;

        .video {
            width: 100%;
            height: 175px;
        }

        .swiperBox {
            box-sizing: border-box;
            padding: 0px 10px;
            margin-top: -160px;


            .swiper-container {
                width: 100%;

                .swiper-wrapper {
                    .swiper-slide {
                        width: 120px !important;
                        height: 90px;
                        border-radius: 8px;
                        text-align: center;
                        color: #fff;
                        font-size: 12px;
                        box-sizing: border-box;
                        padding: 50px 8px 0;
                    }

                    .one {
                        background: url('/subject/tg_9654/s8.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .two {
                        background: url('/subject/tg_9654/s9.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .three {
                        background: url('/subject/tg_9654/s10.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .four {
                        background: url('/subject/tg_9654/s11.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .five {
                        background: url('/subject/tg_9654/s12.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .six {
                        background: url('/subject/tg_9654/s13.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

    .bottomBox {
        width: 100%;
        height: 220px;
        background: url('/subject/tg_9654/wap/s14.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 135px 20px 0;
        display: flex;
        justify-content: space-between;

        div {
            width: 50%;
            height: 58px;
            cursor: pointer;
        }
    }
}

.step_5 {
    width: 100%;
    background: linear-gradient(#1a2840, #253b60);
    box-sizing: border-box;
    padding: 25px 0;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .container {
        width: 100%;
        height: 480px;
        background: url('/subject/tg_9654/wap/s17.png') no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;

        .swiperBox {
            width: 100%;
            height: 270px;

            .swiper-container {
                width: 100%;
                height: 270px;

                .swiper-wrapper {
                    .swiper-slide {
                        img {
                            height: 140px;
                            object-fit: cover;
                        }

                        .one {
                            display: block;
                        }

                        .two {
                            display: none;
                        }

                        /* Center slide text vertically */
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                        transition: 300ms;
                    }

                    .swiper-slide-active,
                    .swiper-slide-duplicate-active {

                        img {
                            height: 200px;
                        }

                        .two {
                            display: block;
                        }

                        .one {
                            display: none;
                        }
                    }
                }
            }
        }

        .btnBox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            margin-top: 120px;

            div {
                width: 50%;
                height: 50px;
                cursor: pointer;
            }
        }
    }
}

.step_6 {
    width: 100%;
    background: linear-gradient(#f7faff, #deedff);
    box-sizing: border-box;
    padding: 25px 0;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .cTitle {
        display: block;
        height: 19px;
        margin: 18px auto 10px;
    }

    .container {
        width: 100%;
        background: linear-gradient(#f7faff, #deedff);
        box-sizing: border-box;
        padding: 25px 10px 25px 35px;
        color: #292f62;
        font-size: 13px;
        border: 1px solid #fff;
        border-radius: 10px;

        .item {
            width: 100%;
            display: flex;

            &:not(:nth-child(1)) {
                margin-top: -10px;
            }

            .left {
                width: 60px;
                text-align: right;
                box-sizing: border-box;
                padding-right: 25px;
                font-weight: 600;
            }

            .right {
                width: 300px;
                border-left: 1px dashed #1462ff;
                margin-top: 1px;
                padding-bottom: 25px;

                .titleBox {
                    position: relative;
                    left: -7px;
                    font-weight: 600;

                    .point {
                        float: left;
                        width: 10px;
                        height: 10px;
                        background-color: #fff;
                        border: 1px solid #1462ff;
                        border-radius: 10px;
                    }

                    span {
                        margin-left: 16px;
                        position: relative;
                        top: -3px;
                    }
                }

                .text {
                    box-sizing: border-box;
                    padding-left: 21px;
                }
            }
        }
    }

    .btnBox {
        width: 90%;
        height: 66px;
        background: url('/subject/tg_9654/wap/s22.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin: 17px auto 0;

        div {
            width: 50%;
            height: 50px;
            cursor: pointer;
        }
    }
}

.step_7 {
    width: 100%;
    height: 865px;
    background: url('/subject/tg_9654/wap/s23.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 25px;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .container {
        width: 100%;
        height: 750px;
        background: url('/subject/tg_9654/wap/s25.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        margin-top: 25px;
        padding-top: 25px;

        .titleBox {

            img {
                display: block;

                &:nth-child(1) {
                    width: 90%;
                    margin: 0 auto;
                }

                &:nth-child(2) {
                    width: 60%;
                    cursor: pointer;
                    margin: 10px auto 5px;
                }

                &:nth-child(3) {
                    width: 40%;
                    margin: 0 auto;
                }
            }
        }

        .swiperBox {
            width: 100%;
            height: 278px;
            margin: 15px auto;

            .swiper-container {
                width: 100%;
                height: 278px;

                .swiper-wrapper {
                    .swiper-slide {
                        img {
                            height: 190px;
                            object-fit: cover;
                            position: relative;
                            top: 36px;
                        }

                        /* Center slide text vertically */
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                        transition: 300ms;
                        transform: scale(1);
                    }

                    .swiper-slide-active,
                    .swiper-slide-duplicate-active {
                        transform: scale(1.4);
                        margin-top: 0;

                        img {
                            position: relative;
                            top: 0px;
                        }
                    }
                }
            }
        }

        .btnBox {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin: 163px auto 0;

            div {
                width: 50%;
                height: 60px;
                cursor: pointer;
            }
        }
    }
}

.step_8 {
    width: 100%;
    background: linear-gradient(#f7faff, #deedff);
    box-sizing: border-box;
    padding: 25px 0;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .container {
        display: block;
        width: 100%;
        margin: 25px auto 0;
    }
}

.step_9 {
    width: 100%;
    height: 540px;
    background: url('/subject/tg_9654/wap/s35.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 25px;

    .topTitle {
        display: block;
        height: 35px;
        margin: 0 auto;
    }

    .container {
        display: block;
        width: 85%;
        margin: 25px auto 0;
    }
}

/deep/ .videoPlayer-dimensions {
    width: 344px;
    height: 165px;
}
</style>